// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "s_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "s_fQ d_fQ d_by d_bH";
export var menuDesign6 = "s_rV d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "s_rW d_fL d_by d_dv d_bN d_bH";
export var menuRight = "s_rX d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "s_rY d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "s_rZ d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "s_ql d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "s_qw d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "s_r0";
export var navbarItem = "s_qm d_bw";
export var navbarLogoItemWrapper = "s_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "s_r1 d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "s_r2 d_gc d_bx d_Y d_br";
export var burgerToggle = "s_r3 d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "s_r4 d_gc d_bx d_Y d_br";
export var burgerInput = "s_r5 d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "s_r6 d_f2 d_v d_G";
export var burgerLine = "s_r7 d_f0";
export var burgerMenuDesign6 = "s_r8 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "s_r9 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "s_sb d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "s_sc d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "s_sd d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "s_sf d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "s_sg d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "s_sh d_bB d_bN";
export var staticBurger = "s_sj";
export var menu = "s_sk";
export var navbarDividedLogo = "s_sl";
export var nav = "s_sm";