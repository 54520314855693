// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "m_pM d_gt d_cr";
export var heroHeaderCenter = "m_gv d_gv d_cr d_dv";
export var heroHeaderRight = "m_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "m_pN d_gq d_cv";
export var heroParagraphCenter = "m_gr d_gr d_cv d_dv";
export var heroParagraphRight = "m_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "m_pP d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "m_pQ d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "m_pR d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "m_pS d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "m_pT d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "m_pV r_pV";
export var heroExceptionNormal = "m_pW r_pW";
export var heroExceptionLarge = "m_pX r_pX";
export var Title1Small = "m_pY r_pY r_qM r_qN";
export var Title1Normal = "m_pZ r_pZ r_qM r_qP";
export var Title1Large = "m_p0 r_p0 r_qM r_qQ";
export var BodySmall = "m_p1 r_p1 r_qM r_q4";
export var BodyNormal = "m_p2 r_p2 r_qM r_q5";
export var BodyLarge = "m_p3 r_p3 r_qM r_q6";